import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import {
  BodyWrapper,
  GlobalStyles,
  SiteHeader,
  PageHeader,
  ImageGrid,
  Footer
} from '../../components'
import { colours } from '../../styles'
import AOS from 'aos'
import 'aos/dist/aos.css'

const { midnight, white } = colours

const Page = ({ data }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: 'ease-in-out',
      offset: 100,
      once: true
    })
    window.sessionStorage.setItem('introComplete', 1)
  }, [])
  console.log(data)
  return (
    <>
      <Helmet>
        <html lang='en' />
        <meta charSet='utf-8' />
        <title>Portfolio - Inspire Me | Vicki Louise Ward Fashion Illustrator</title>
        <meta name='description' content='Find inspiring illustrations and artwork by Vicki Louise Ward, for brands and themes including: SkinCeuticals, Perricone MD, Net-A-Porter &amp; Mr Porter, Smooth Skin, BySarah, Fashion Week, Interiors, Urban Decay, YSL, Florals, Beauty products, STYLIST, Yoga' />
      </Helmet>
      <GlobalStyles />
      <BodyWrapper>
        <SiteHeader />
        <PageHeader
          color={white}
          bgcolor={midnight}
          image={data.splash_bp4_1.childImageSharp.fluid}
          title='Get inspired'
          text='SkinCeuticals / Perricone MD / Net-A-Porter &amp; Mr Porter / Smooth Skin / BySarah / Fashion Week / Interiors / Urban Decay / YSL / Florals / Beauty products / STYLIST / Yoga'
        />
        <ImageGrid
          columns={[
            [
              data.beauty_01.childImageSharp.fluid,
              data.figure_01.childImageSharp.fluid,
              data.runway_02.childImageSharp.fluid,
              data.beauty_02.childImageSharp.fluid,
              data.blooming_bouquet.childImageSharp.fluid,
              data.ynap_mrp.childImageSharp.fluid
            ],
            [
              data.runway_01.childImageSharp.fluid,
              data.figure_02.childImageSharp.fluid,
              data.skinceuticals_01.childImageSharp.fluid,
              data.shoe_01.childImageSharp.fluid,
              data.smoothskin_01.childImageSharp.fluid,
              data.skinceauticals_02.childImageSharp.fluid,
              data.tulum_01.childImageSharp.fluid,
              data.yoga_01.childImageSharp.fluid,
              data.ynap_festive_02.childImageSharp.fluid
            ],
            [
              data.interior_01.childImageSharp.fluid,
              data.skull_01.childImageSharp.fluid,
              data.figure_03.childImageSharp.fluid,
              data.ynap_beauty_mindset.childImageSharp.fluid,
              data.figures_04.childImageSharp.fluid,
              data.tulum_02.childImageSharp.fluid
            ]
          ]}
        />
        <Footer />
      </BodyWrapper>
    </>
  )
}
export const query = graphql`
  query {
    splash_bp4_1: file(relativePath: { eq: "homepage/splashscreen/Image-exportsbp4_home_splash_1.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    beauty_01: file(relativePath: { eq: "inspire-me/beauty_01.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    figure_01: file(relativePath: { eq: "inspire-me/figure_01.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    runway_02: file(relativePath: { eq: "inspire-me/runway_02.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    beauty_02: file(relativePath: { eq: "inspire-me/beauty_02.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    blooming_bouquet: file(relativePath: { eq: "inspire-me/blooming_bouquet.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ynap_mrp: file(relativePath: { eq: "inspire-me/ynap_mrp.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    runway_01: file(relativePath: { eq: "inspire-me/runway_01.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    figure_02: file(relativePath: { eq: "inspire-me/figure_02.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    skinceuticals_01: file(relativePath: { eq: "inspire-me/skinceuticals_01.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    shoe_01: file(relativePath: { eq: "inspire-me/shoe_01.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    smoothskin_01: file(relativePath: { eq: "inspire-me/smoothskin_01.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    skinceauticals_02: file(relativePath: { eq: "inspire-me/skinceauticals_02.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tulum_01: file(relativePath: { eq: "inspire-me/tulum_01.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    yoga_01: file(relativePath: { eq: "inspire-me/yoga_01.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ynap_festive_02: file(relativePath: { eq: "inspire-me/ynap_festive_02.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    interior_01: file(relativePath: { eq: "inspire-me/interior_01.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    skull_01: file(relativePath: { eq: "inspire-me/skull_01.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    figure_03: file(relativePath: { eq: "inspire-me/figure_03.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    ynap_beauty_mindset: file(relativePath: { eq: "inspire-me/ynap_beauty_mindset.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    figures_04: file(relativePath: { eq: "inspire-me/figures_04.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    tulum_02: file(relativePath: { eq: "inspire-me/tulum_02.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default Page
